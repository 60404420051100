import React from 'react';
import styled from 'styled-components';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const DialogContent = styled.div`
  background-color: black;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 3px solid limegreen;
  text-align: left;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Title = styled.h3`
  margin-top: 0;
  color: limegreen;
  padding-right: 30px; // Increased to accommodate larger close button
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 32px; // Increased font size
  cursor: pointer;
  color: limegreen;
  padding: 0;
  line-height: 1;
  width: 32px; // Set a fixed width
  height: 32px; // Set a fixed height
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExampleTitle = styled.h4`
  margin-top: 0;
  margin-bottom: 4px;
`;

const ExampleList = styled.ul`
  margin-top: 0;
  padding: 0 10px;
`;

const InstructionImage = styled.img`
  height: var(--ascii-box-size);
  margin: 2px 0;
  // border: 2px solid limegreen;
  // border-radius: 4px;
`;

const TicTacToeInstructions = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <DialogOverlay onClick={onClose}>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Title>How To Solve For The Key</Title>
        <ol>
        <li>
          Test up to 5 full sets of old fragments against the new key, to gain information about the digits of the new key.
        </li>
        <li>
          Then you get 1 chance to type in the full new key; it <b>won’t</b> be made up of exact copies of old fragments.
        </li>
        </ol>
        
        <ExampleTitle>History Examples:</ExampleTitle> 
        <ExampleList>
          <InstructionImage src="/7CorrectFull.png" alt="7 correct" /> <br></br>
          <li>The <b>7</b> is the correct number in the correct place.</li>
          <br></br>

          <InstructionImage src="/5LowFull.png" alt="5 too low" /> <br></br>
          <li>The number in this location is greater than <b>5</b>.</li>
          <br></br>

          <InstructionImage src="/0RightFull.png" alt="9 to the right" /> <br></br>
          <li>Not only is the number in this location greater than 0; there is at least one <b>0</b> in the answer somewhere to the right of this location.</li>
        </ExampleList>
      </DialogContent>
    </DialogOverlay>
  );
};

export default TicTacToeInstructions;