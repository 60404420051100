import React from 'react';
import './App.css';
import AsciiBox from './AsciiBox';
import DigitGroup from './DigitGroup';
import classNames from 'classnames';
import TicTacToeInstructions from './TicTacToeInstructions';
import WinDialog from './WinDialog';
import WarningDialog from './WarningDialog';

class CharacterSlot {
  constructor(answer) {
    this.answer = answer;
    this.foundAnswer = false;
    this.options = [true, true, true, true, true, true, true, true, true, true];
    this.eliminatedOptionsCount = 0;
  }
  // Getter
  getFoundAnswer() {
    return this.foundAnswer;
  }

  setFoundAnswer(input) {
    this.foundAnswer = input;
    if(input) {
      console.log('All but ' + this.answer);
      for(let i = 0; i <= 9; i++) {
        if((i+"") !== this.answer) {
          this.setOptionFalse(i);
        }
      }
    }
  }

  // Method
  setOptionFalse(number) {
    if(this.options[number]) {
      this.options[number] = false;
      this.eliminatedOptionsCount++;
      if(this.eliminatedOptionsCount === 9) {
        this.foundAnswer = true;
      }
    }
  }
  updateLessThan(number) {
    for(let i = number; i <= 9; i++) {
      this.setOptionFalse(i);
    }
  }
  updateGreaterThan(number) {
    for(let i = number; i >= 0; i--) {
      this.setOptionFalse(i);
    }
  }

  isPossibleOption(number) {
    return this.options[number];
  }
}

class CharacterBox extends React.Component {
  render() {
    let classes;
    if(this.props.showHints === "true" ) {
      const answerChar = this.props.answer[this.props.index];
      let copyToTheLeft = false;
      for (let j = 0; j < this.props.index; j++) {
          if(this.props.value === this.props.answer[j]) {
              copyToTheLeft = true;
              break;
          }
      }

      let copyToTheRight = false;
      for (let j = this.props.index+1; j < this.props.answer.length; j++) {
          if(this.props.value === this.props.answer[j]) {
              copyToTheRight = true;
              break;
          }
      }

      classes = classNames({
        'passwordcharacterbase': true,
        'correctcharacter': this.props.value === answerChar,
        'numberTooHigh': this.props.value > answerChar,
        'numberTooLow': this.props.value < answerChar,
        'copyToTheLeft': copyToTheLeft,
        'copyToTheRight': copyToTheRight,
      }) 
    }
    else {
      classes = classNames({
        'passwordcharacterbase': true
      });
    }

    return <span className={classes}>{this.props.value}</span>;
  }
}

class PasswordSet extends React.Component {
  constructor(props) {
    const numbers = props.numbers;
    super(props);
    this.state = {
      passwordArray: this.getPasswordArray(numbers)
    };
    this.setPassword = this.setPassword.bind(this);
  }

  getPasswordArray (newPassword) {
    const lengthOfSet = 9;
    let passwordCharArray = ["\xa0", "\xa0", "\xa0", "\xa0", "\xa0", "\xa0", "\xa0", "\xa0", "\xa0"];
    let numbersArray = newPassword.split("");
    for(let i = 0; i < lengthOfSet && i < numbersArray.length; i++) {
      passwordCharArray[i] = numbersArray[i];
    }
    return passwordCharArray;
  }
  setPassword (newPassword) {
    this.setState({passwordArray: this.getPasswordArray(newPassword)});
  }
  
  render() {
    let numbers = this.props.numbers;
    let passwordArray = this.getPasswordArray(numbers);
    console.log(passwordArray);
    
    return (
      <span className="ascii-box-container">
        {passwordArray.map((number, index) => 
          // <CharacterBox key={index.toString()}
          //           value={number} 
          //           answer={this.props.answer}
          //           index={index}
          //           showHints={this.props.showHints}/>
          <AsciiBox
            key={index.toString()}
            value={number} 
            numbers={numbers}
            answer={this.props.answer}
            index={index}
            showHints={this.props.showHints}
            onChange={() => {}}
            // onKeyDown={(e) => handleKeyDown(index, e)}
            // ref={(el) => inputRefs.current[index] = el}
            downSolid={index%2}
            upSolid={!(index%2)}
          />
        
        )}
      </span>
    );
  }
}
function PeiceSet(props) {
  const peiceString = props.peiceString;
  function onPeiceClick () {
    props.addToCurrentGuess(props.peiceString);
    console.log(peiceString);
  }
  return (
    <span className="peiceSet" onClick={onPeiceClick}>
      {peiceString.split("").map((number, index) =>
        <CharacterBox key={index.toString()}
                  value={number} 
                  index={index}
                  showHints="false"/>
      )}
    </span>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentGuess: "",
      attempts: [],
      keyFragments: ["", "", "", "", "", ""],
      answer: "723456789",
      answerFieldText: "",
      isDialogOpen: true,
      isWinDialogOpen: false,
      isWarningDialogOpen: false,
      warning: "",
      hasWon: false,
      isMobileLayout: false,
      firstSlider: true,
      digits: Array(9).fill('')
    };
    this.addAttempt = this.addAttempt.bind(this);
    this.clear = this.clear.bind(this);
    this.addToCurrentGuess = this.addToCurrentGuess.bind(this);
    this.newKey = this.newKey.bind(this);
    this.setAnswerFieldText = this.setAnswerFieldText.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.setIsDialogOpen = this.setIsDialogOpen.bind(this);
    this.setIsWinDialogOpen = this.setIsWinDialogOpen.bind(this);
    this.setIsWarningDialogOpen = this.setIsWarningDialogOpen.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.setDigits = this.setDigits.bind(this);
  }

  componentDidMount() {
    this.newKey();
    this.updateLayout(); // Set initial layout
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    this.setState({ isMobileLayout: window.innerWidth < 1010 });
  }

  setFirstSlider = (value) => {
    this.setState({ firstSlider: value });
  }

  isNumberToRightOfIndex(arr, targetNumber, startIndex) {
    // Check if the startIndex is valid
    if (startIndex < 0 || startIndex >= arr.length) {
      throw new Error('Invalid startIndex');
    }
  
    // Iterate through the array from the startIndex
    for (let i = startIndex; i < arr.length; i++) {
      if (arr[i] === targetNumber) {
        return true; // Found the target number
      }
    }
  
    return false; // Target number not found after the given index
  }

  isNumberToLeftOfIndex(arr, targetNumber, endIndex) {
    // Check if the endIndex is valid
    if (endIndex < 0 || endIndex >= arr.length) {
      throw new Error('Invalid endIndex');
    }
  
    // Iterate through the array before the endIndex
    for (let i = 0; i < endIndex; i++) {
      if (arr[i] === targetNumber) {
        return true; // Found the target number
      }
    }
  
    return false; // Target number not found before the given index
  }

  newKey() {
    let newFeyFragments = [];
    let newAnswer = String((Math.floor(Math.random()*1000000000))).padStart(9, '0');
    for(let i = 0; i < 6; i++) {
      newFeyFragments.push(String(Math.floor(Math.random()*1000)).padStart(3, '0'));
    }

    console.log(newAnswer);

    let rec = new CharacterSlot(8);
    rec.updateGreaterThan(9);
    rec.updateLessThan(7);
    console.log(rec.getFoundAnswer());

    let characterSlotArray = [];

    let answerArray = newAnswer.split("");
    let solvedSoFar = [{}, {}, {}, {}, {}, {}, {}, {}, {}]
    answerArray.forEach(function(answerNum, index) {
      characterSlotArray.push(new CharacterSlot(answerNum));
    });
    //Exact matches and high/low eliminations
    answerArray.forEach(function(answerNum, index) {
      let mod = index % 3;
      newFeyFragments.forEach(function(curKeyFragment) {
        if(curKeyFragment[mod] === answerNum) {
          characterSlotArray[index].setFoundAnswer(true);
          console.log('Answer at ' + index + ' is ' + curKeyFragment[mod]);
        }
        else if (curKeyFragment[mod] > answerNum) {
          characterSlotArray[index].updateLessThan(curKeyFragment[mod]);
        }
        else {
          characterSlotArray[index].updateGreaterThan(curKeyFragment[mod]);
        }
      })
    });
    //left eliminations
    answerArray.forEach(function(answerNum, index) {
      let mod = index % 3;
      newFeyFragments.forEach(function(curKeyFragment) {
        if(curKeyFragment[mod] !== answerNum) {
          let matchToTheLeft = false;
          for(let i = 0; i < index; i++) {
            if(answerArray[i] === curKeyFragment[mod]) {
              matchToTheLeft = true;
              break;
            }
          }
          if(!matchToTheLeft) {
            for(let i = 0; i < index; i++) {
              characterSlotArray[i].setOptionFalse(curKeyFragment[mod]);
            }
          }
        }
      })
    });
    //right eliminations
    answerArray.forEach(function(answerNum, index) {
      let mod = index % 3;
      newFeyFragments.forEach(function(curKeyFragment) {
        if(curKeyFragment[mod] !== answerNum) {
          let matchToTheRight = false;
          for(let i = 8; i > index; i--) {
            if(answerArray[i] === curKeyFragment[mod]) {
              matchToTheRight = true;
              break;
            }
          }
          if(!matchToTheRight) {
            for(let i = 8; i > index; i--) {
              characterSlotArray[i].setOptionFalse(curKeyFragment[mod]);
            }
          }
        }
      })
    });
    //Left it by default
    answerArray.forEach(function(answerNum, index) {
      let mod = index % 3;
      newFeyFragments.forEach(function(curKeyFragment) {
        if(curKeyFragment[mod] !== answerNum) {
          let matchesToTheLeft = 0;
          let lastMatchedIndex = -1;
          for(let i = 0; i < index; i++) {
            if(characterSlotArray[i].isPossibleOption(curKeyFragment[mod])) {
              matchesToTheLeft++;
              lastMatchedIndex = i;
            }
          }
          if(matchesToTheLeft === 1) {
            // console.log(matchesToTheLeft + " " + lastMatchedIndex);
            characterSlotArray[lastMatchedIndex].setFoundAnswer(true);
            console.log('Answer at ' + lastMatchedIndex + ' is ' + curKeyFragment[mod] + ' by default');
          }
        }
      })
    });
    //Right it by default
    answerArray.forEach(function(answerNum, index) {
      let mod = index % 3;
      newFeyFragments.forEach(function(curKeyFragment) {
        if(curKeyFragment[mod] !== answerNum) {
          let matchesToTheRight = 0;
          let lastMatchedIndex = -1;
          for(let i = 8; i > index; i--) {
            if(characterSlotArray[i].isPossibleOption(curKeyFragment[mod])) {
              matchesToTheRight++;
              lastMatchedIndex = i;
            }
          }
          if(matchesToTheRight === 1) {
            characterSlotArray[lastMatchedIndex].setFoundAnswer(true);
            console.log('Answer at ' + lastMatchedIndex + ' is ' + curKeyFragment[mod] + ' by default');
          }
        }
      })
    });


    console.log('characterSlotArray:');
    console.log(characterSlotArray);
    
    // //old
    // answerArray.forEach(function(answerNum, index) {

    //   let mod = index % 3;
    //   newFeyFragments.forEach(function(curKeyFragment) {
    //     if(curKeyFragment[mod] === answerNum) {
    //       solvedSoFar[index].answer = curKeyFragment[mod];
    //       console.log(index + ": " + curKeyFragment[mod]);
    //     }
    //     else if (curKeyFragment[mod] > answerNum) {
    //       solvedSoFar[index].max = !!solvedSoFar[index].max? Math.min(solvedSoFar[index].max, curKeyFragment[mod]) : curKeyFragment[mod];
    //     }
    //     else {
    //       solvedSoFar[index].min = !!solvedSoFar[index].min? Math.max(solvedSoFar[index].min, curKeyFragment[mod]) : curKeyFragment[mod];
    //     }
    //   })
    // })
    // solvedSoFar.forEach(function(curObject, index) {
    //   if(typeof curObject.answer === 'undefined') {
    //     curObject.min = typeof curObject.min === 'undefined' ? -1 : curObject.min;
    //     curObject.max = typeof curObject.max === 'undefined' ? 10 : curObject.max;

    //     if((curObject.max - curObject.min) === 2) {
    //       curObject.answer = curObject.min + 1;
    //     }
    //   }
    // })
    // solvedSoFar.forEach(function(curObject, index) {
    //   if(typeof curObject.answer === 'undefined') {
    //     //eliminate other options only leaving the right one
    //   }
    // })
    // solvedSoFar.forEach(function(curObject, index) {
    //   if(typeof curObject.answer === 'undefined') {
    //     // Side says number is this side and this is only spot it fits
    //   }
    // })

    // console.log(solvedSoFar);



    //Set the state of the current values;
    this.setState(prevState => ({
      keyFragments: newFeyFragments,
      answer: newAnswer,
      currentGuess: "",
      attempts: [],
      answerFieldText: "",
      isWinDialogOpen: false,
      hasWon: false,
      firstSlider: true,
      digits: Array(9).fill('')
    }))

    

    let isAllAnswered = true;
    characterSlotArray.forEach(function(characterSlot, index) {
      if(!characterSlot.getFoundAnswer()) {
        isAllAnswered = false;
        return false;
      }
    });
    console.log(isAllAnswered);
    if(!isAllAnswered) {
      console.log(this.newKey);
      if(!this.totalCount) {
        this.totalCount = 0;
      }
      this.totalCount++;

      this.newKey();
    }
    else {
      console.log('Total tries: ' + this.totalCount);
      // alert('Total tries: ' + this.totalCount);
      this.totalCount = 0;
    }
  }

  submitAnswer() {
    // This has been moved to the DigitGroup file
  }


  setIsDialogOpen(input) {
    this.setState(prevState => ({
      isDialogOpen: input
    }));
  }

  setIsWinDialogOpen(input, won) {
    this.setState(prevState => ({
      isWinDialogOpen: input,
      hasWon: won
    }));
  }

  setIsWarningDialogOpen(input, warning) {
    this.setState(prevState => ({
      isWarningDialogOpen: input,
      warning: warning
    }));
  }

  setDigits(input) {
    this.setState(prevState => ({
      digits: input
    }));
  }

  setAnswerFieldText (input) {
    this.setState(prevState => ({
      answerFieldText: input
    }));
  }

  addAttempt() {
    if(this.state.currentGuess.length < 9) {
      this.setIsWarningDialogOpen(true, "Select 3 old key fragments to make a full 9 digit test");
    }
    else {
      this.setState(prevState => ({
        attempts: [prevState.currentGuess, ...this.state.attempts],
        currentGuess: ""
      }));
    }
  }

  clear() {
    this.setState({currentGuess: ""});
  }

  addToCurrentGuess(newGuessAddition) {
    this.setState(prevState => ({
      currentGuess: (prevState.currentGuess + newGuessAddition).substring(0, 9)
    }));
  }



  render() {
    return (
      <div className={(this.state.isMobileLayout? "mobile" : "desktop") + " App"}>
        <button className='helpButton' onClick={() => this.setIsDialogOpen(true)}>
            ?
          </button>
        <div className="leftColumn">
          
      <TicTacToeInstructions
        isOpen={this.state.isDialogOpen}
        onClose={() => this.setIsDialogOpen(false)}
      />
      <WinDialog
        isOpen={this.state.isWinDialogOpen}
        onClose={() => this.setIsWinDialogOpen(false, false)}
        hasWon={this.state.hasWon}
        answer={this.state.answer}
        newKey={this.newKey}
      />
      <WarningDialog
        isOpen={this.state.isWarningDialogOpen}
        onClose={() => this.setIsWarningDialogOpen(false, false)}
        warning={this.state.warning}
      />
          {this.state.attempts.length < 5?
          <div>
            <div>
              <b>Select old fragments <br></br>to test against new key</b>
            </div>
            <PasswordSet numbers={this.state.currentGuess} answer="723456789" showHints="false"/>
            <div className="buttonContainer">
              <button className="clearButton hackingButton" onClick={this.clear}>Clear</button>
              <button className="submitButton hackingButton" onClick={this.addAttempt}>Test</button>
            </div>
          </div>
          :
          <p className='out-of-tests-text'>
            You have used all 5 fragment tests, you must now guess the full new key. Remember: The new key cannot be directly made by combining 3 fragments. It can be any set of 9 digits. 
          </p>
          }
          <br></br>
          <fieldset className='attemptHistory'>
            <legend>Test History {this.state.attempts.length}/5</legend>
            {this.state.attempts.map((number, index) =>
              <PasswordSet key={index.toString()}
              numbers={number} 
              answer={this.state.answer}
              showHints="true"/>
            )}
          </fieldset>
        </div>
        <div className="rightColumn">
          {this.state.isMobileLayout? 
          <div>
            {this.state.firstSlider?
              <div className="circles" onClick={() => this.setFirstSlider(false)}>&nbsp; ◉ ◯ &gt;</div> 
              :
              <div className="circles" onClick={() => this.setFirstSlider(true)}>&lt; ◯ ◉ &nbsp;</div>
            }
          </div>
          :''}
          {!this.state.isMobileLayout || this.state.firstSlider?
          <div>
            <p>
              <b>Old key fragments:</b>
            </p>
            <div>
            <PeiceSet peiceString={this.state.keyFragments[0]} addToCurrentGuess={this.addToCurrentGuess}/>
            <PeiceSet peiceString={this.state.keyFragments[1]} addToCurrentGuess={this.addToCurrentGuess}/>
            <br></br>
            <PeiceSet peiceString={this.state.keyFragments[2]} addToCurrentGuess={this.addToCurrentGuess}/>
            <PeiceSet peiceString={this.state.keyFragments[3]} addToCurrentGuess={this.addToCurrentGuess}/>
            <br></br>
            <PeiceSet peiceString={this.state.keyFragments[4]} addToCurrentGuess={this.addToCurrentGuess}/>
            <PeiceSet peiceString={this.state.keyFragments[5]} addToCurrentGuess={this.addToCurrentGuess}/>
            </div>
          </div>
          : ''}
          {!this.state.isMobileLayout || !this.state.firstSlider?
          <div>
            <DigitGroup digits={this.state.digits} setDigits={this.setDigits} answer={this.state.answer} showKeypad={this.state.isMobileLayout} setIsWinDialogOpen={this.setIsWinDialogOpen} setIsWarningDialogOpen={this.setIsWarningDialogOpen}/>
            {/* <button className='hackingButton' onClick={this.newKey}>New key</button> */}
          </div>
          : ''}
        </div>
      </div>
    );
  }
}

export default App;
