import React, { useState, useEffect, useRef } from 'react';
import './DigitGroup.css';

const DigitComponent = ({ value, isSelected, onSelect }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isSelected) {
      inputRef.current.focus();
    }
  }, [isSelected]);

  return (
    <div
      ref={inputRef}
      tabIndex={0}
      onClick={() => onSelect()}
      onFocus={() => onSelect()}
      className={`digit ${isSelected ? 'digit-selected' : ''}`}
    >
      <span className="digit-content">
        {value || ' '}
      </span>
    </div>
  );
};

const NumberButton = ({ symbol, onClick }) => (
  <button
    onClick={(e) => {
      e.preventDefault();
      onClick(symbol);
    }}
    className="number-button"
  >
    {symbol}
  </button>
);

const DigitGroup = (props) => {
  const digits = props.digits;
  const setDigits = props.setDigits;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const groupRef = useRef(null);
  const answer = props.answer;
  const setIsWinDialogOpen = props.setIsWinDialogOpen;
  const setIsWarningDialogOpen = props.setIsWarningDialogOpen;

  
  const handleKeyPress = (key) => {
    let newSelectedIndex = selectedIndex;
  
    // Only find the first empty digit if no digit is currently selected
    if (selectedIndex === null) {
      newSelectedIndex = digits.findIndex(digit => digit === '');
      if (newSelectedIndex === -1) newSelectedIndex = 8; // If all filled, select the last digit
      setSelectedIndex(newSelectedIndex);
    }
  
    const newDigits = [...digits];
  
    switch (key) {
      case 'Backspace':
      case '⌫':
        if (newSelectedIndex !== null) {
          newDigits[newSelectedIndex] = '';
          setDigits(newDigits);
          if (newSelectedIndex > 0) {
            setSelectedIndex(newSelectedIndex - 1);
          }
        }
        break;
      case 'Delete':
        if (newSelectedIndex !== null) {
          newDigits[newSelectedIndex] = '';
          setDigits(newDigits);
        }
        break;
      case 'ArrowLeft':
        if (newSelectedIndex !== null) {
          setSelectedIndex(Math.max(0, newSelectedIndex - 1));
        }
        break;
      case 'ArrowRight':
        if (newSelectedIndex !== null) {
          setSelectedIndex(Math.min(8, newSelectedIndex + 1));
        }
        break;
      case ' ':
      case '␣':
        if (newSelectedIndex !== null) {
          newDigits[newSelectedIndex] = '';
          setDigits(newDigits);
          if (newSelectedIndex < 8) {
            setSelectedIndex(newSelectedIndex + 1);
          }
        }
        break;
      default:
        if (/^[0-9]$/.test(key) && newSelectedIndex !== null) {
          newDigits[newSelectedIndex] = key;
          setDigits(newDigits);
          if (newSelectedIndex < 8) {
            setSelectedIndex(newSelectedIndex + 1);
          }
        }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleKeyPress(event.key);
      // event.preventDefault();
    };

    const handleClickOutside = (event) => {
      if (groupRef.current && !groupRef.current.contains(event.target)) {
        setSelectedIndex(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedIndex, digits]);

  
  const submitAnswer = () => {
    let currentGuess = digits.join('');
    if(currentGuess.length < 9) {
      setIsWarningDialogOpen(true, "You only get to submit the key once. Remember to fill in all 9 digits first.")
    }
    else {
      if (currentGuess === answer) {
        setIsWinDialogOpen(true, true);
      } else {
        setIsWinDialogOpen(true, false);
      }
    }
  };

  const handleButtonClick = (symbol) => {
    handleKeyPress(symbol);
  };

  const buttonLayout = [
    ['0', '1', '2', '3'],
    ['4', '5', '6', '7'],
    ['8', '9', '⌫', '␣'],
  ];

  return (
    <div ref={groupRef} className="digit-group">

      <dev className="key-label">
          <b>New Key:</b>
      </dev>
      <div className="digit-container">
        {digits.map((digit, index) => (
          <DigitComponent
            key={index}
            value={digit}
            isSelected={index === selectedIndex}
            onSelect={() => setSelectedIndex(index)}
          />
        ))}
      </div>
      <div className='submitButtonContainer'><button className="answerButton hackingButton" onClick={submitAnswer}>Submit Key</button></div>
      {props.showKeypad || true?
      <div className="number-pad">
        {buttonLayout.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.map((symbol) => (
              <NumberButton key={symbol} symbol={symbol} onClick={handleButtonClick} />
            ))}
          </React.Fragment>
        ))}
      </div>
       : ''}
    </div>
  );
};

export default DigitGroup;