// AsciiBox.js
import React, { forwardRef } from 'react';
import './AsciiBox.css';

// const AsciiBox = forwardRef(({ value, onChange, onKeyDown, downSolid, upSolid}, ref) => {
class AsciiBox extends React.Component {
    render() {
    let classes;
    let correctcharacter = false;
    let numberTooHigh = false;
    let numberTooLow = false;
    let copyToTheLeft = false;
    let copyToTheRight = false;
    let plainBorder = false;
    if(this.props.showHints === "true" ) {
      const answerChar = this.props.answer[this.props.index];
      copyToTheLeft = false;
      for (let j = this.props.index - 1; j >= 0; j--) {
        if(this.props.value === this.props.numbers[j]) {
            
            //if the next matching number, is in the guess. Then do not point towards it. 
            break;
        }
          if(this.props.value === this.props.answer[j]) {
              copyToTheLeft = true;
              break;
          }
      }

      copyToTheRight = false;
      for (let j = this.props.index+1; j < this.props.answer.length; j++) {
        if(this.props.value === this.props.numbers[j]) {
            
            //if the next matching number, is in the guess. Then do not point towards it. 
            break;
        }
          if(this.props.value === this.props.answer[j]) {
              copyToTheRight = true;
              break;
          }
      }

    //   classes = classNames({
    //     'passwordcharacterbase': true,
    //     'correctcharacter': this.props.value === answerChar,
    //     'numberTooHigh': this.props.value > answerChar,
    //     'numberTooLow': this.props.value < answerChar,
    //     'copyToTheLeft': copyToTheLeft,
    //     'copyToTheRight': copyToTheRight,
    //   }) 
        plainBorder = correctcharacter = this.props.value === answerChar;
        numberTooHigh = this.props.value > answerChar;
        numberTooLow = this.props.value < answerChar;
    }
    else {
        plainBorder = true;
    //   classes = classNames({
    //     'passwordcharacterbase': true
    //   });
    }
    return (
    <div
        style={{
                // flex: 1,
                padding: 2,
                flexDirection: 'column',
                // fontSize: 15,
                lineHeight: 0.6,
                display: 'flex'
              }}
        className='ascii-box'>
        <div style={{ width: "100%", flexDirection: 'row', display: 'flex' }} >
            <div style={{}}>+</div>
            <div style={{ flex: 1 }}>-</div>
            <div style={{ flex: 1 }}>{plainBorder ? '-' : (numberTooLow ? <span className='border-arrow'>▲</span> : '△')}</div>
            <div style={{ flex: 1 }}>-</div>
            <div style={{ }}>+</div>
        </div>
        <div style={{ flex: 2, width: "100%", flexDirection: 'row', display: 'flex' }} >
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <div style={{ flex: 1 }}></div>
                <div style={{ }}>|</div>
                <div style={{ flex: 2 }}></div>
                <div style={{ }}>{copyToTheLeft? <span className='border-arrow'>◀</span> : '|'}</div>
                <div style={{ flex: 2 }}></div>
                <div style={{ }}>|</div>
                <div style={{ flex: 1 }}></div>
            </div>
            <div style={{ flex: 1 }}>
                <span
                type="text"
                value={this.props.value}
                onChange={this.props.onChange}
                onKeyDown={this.props.onKeyDown}
                ref={this.props.ref}
                maxLength="1"
                className={correctcharacter? 'correct-character asciibox-inner': 'asciibox-inner'}
                >{this.props.value}</span>
            </div>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <div style={{ flex: 1 }}></div>
                <div style={{ }}>|</div>
                <div style={{ flex: 2 }}></div>
                <div style={{ }}>{copyToTheRight? <span className='border-arrow'>▶</span> : '|'}</div>
                <div style={{ flex: 2 }}></div>
                <div style={{ }}>|</div>
                <div style={{ flex: 1 }}></div>
            </div>
        </div>
        <div style={{ width: "100%", flexDirection: 'row', display: 'flex'}} className="bottom" >
            <div style={{ }}>+</div>
            <div style={{ flex: 1 }}>-</div>
            <div style={{ flex: 1 }}>{plainBorder ? '-' : (numberTooHigh ? <span className='border-arrow'>▲</span> : '△')}</div>
            <div style={{ flex: 1 }}>-</div>
            <div style={{ }}>+</div>
        </div>
    </div>
  );
            }
};

export default AsciiBox;
